/* CSS that we can use to provide global styles on top of Tailwind ones */
@font-face {
  font-family: 'DIN';
  src: local('DIN'), url(../fonts/DINCondensed-Bold.ttf) format('trueType');
}

html {
  height: 100%;
  color:#354D5A
}

body {
  height: 100%;
  font-family: 'Poppins';
}

img {
  width: 100%;
}

h1 {
  font-family: 'DIN';
  color: '#354D5A'
}

h2 {
	font-family: "DIN";
	font-size: 1.5rem;
	line-height: 2rem;
  	color: #354D5A;
	text-align: center;
}

h3 {
	font-family: "DIN";
	font-size: 1.5rem;
	line-height: 2rem;
  	color: #354D5A;
	text-align: center;
}

p,label,li {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-family: "Poppins";
  color: #354D5A;
}

.markdown-page {
  max-width: 854px;
  text-align: justify;
  margin: auto;
  margin-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  font-family: "Poppins";
}

.markdown-page a {
  text-decoration: underline;
  color:#c36;
}

.markdown-page p {
  color:#354D5A;
  font-size: 14px;
  line-height: 180%;
}
.markdown-page h1,
.markdown-page h2,
.markdown-page h3 {
  text-align: center;
  font-family: "DIN";
  color:#354D5A;
  margin-top: 8px;
  margin-bottom: 2px;
}

.markdown-page h1 {
  font-size: 40px;
}

.markdown-page h2 {
  font-size: 32px;
}

.markdown-page ul {
  list-style:circle;
  margin-left: 20px;
  font-size: 14px;
  color:#354D5A;
}
.markdown-page li {
  line-height: 180%;
}

@media (min-width:640px) {
	p,label,li {
		font-size: 1rem;
		line-height: 1.5rem;
	}
	h2 {
		font-size: 2.25rem;
		line-height: 2.5rem;
	}
  .markdown-page {
    margin-top:32px;
  }
  .markdown-page p {
    line-height: 200%;
    font-size:16px;
  }
  .markdown-page ul {
    font-size: 16px;
  }
  .markdown-page li {
    line-height: 200%;
  }
  .markdown-page h1, .markdown-page h2 {
    margin-top: 16px;
    margin-bottom: 8px;
  }
}